.ql-container.ql-snow {
  height: 200px;
}
.textBlock p,
.textBlock ul li {
  text-align: left;
}

.textBlock ul li {
  text-align: left;
  list-style-position: inside;
}

.textBlock .ql-align-right,
.textBlock ul li.ql-align-right,
.textBlock ul li.ql-align-right::marker {
  text-align: right !important;
}

.textBlock ul li.ql-align-right::marker {
  text-align-last: right !important;
}

.textBlock .ql-align-left {
  text-align: left;
}

.textBlock .ql-align-center {
  text-align: center;
}

.textBlock p > span.ql-size-small,
.textBlock p > strong.ql-size-small {
  font-size: small;
}
.textBlock li > span.ql-size-small,
.textBlock li > strong.ql-size-small {
  font-size: small;
}
.textBlock p > span.ql-size-large,
.textBlock p > strong.ql-size-large {
  font-size: larger;
}
.textBlock li > span.ql-size-large,
.textBlock li > strong.ql-size-large {
  font-size: larger;
}
.textBlock p > span.ql-size-huge,
.textBlock p > strong.ql-size-huge {
  font-size: xx-large;
}
.textBlock li > span.ql-size-huge,
.textBlock li > strong.ql-size-huge {
  font-size: xx-large;
}

.textBlock li::marker {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center !important;
}